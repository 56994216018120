/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from 'react';
import loadable from '@loadable/component'
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from 'react-router-dom';
const Layout = loadable(() => import('./src/components/layout/layout'));

export const onInitialClientRender = () => {
  setTimeout(function() {
      document.getElementById("___loader").style.display = "none"
  }, 500)
}

export function wrapPageElement({ element, props }) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Layout {...props}>{element}</Layout>;
}

export function wrapRootElement({ element }) {
  const client = new ApolloClient({
    uri: 'https://admin.joystickmaverick.com/graphql',
    cache: new InMemoryCache()
  });

  if(typeof window !== "undefined") {
    return (
      <ApolloProvider client={client}>
        <BrowserRouter>
          {element}
        </BrowserRouter>
      </ApolloProvider>
    );
  }
  
  return null;
}